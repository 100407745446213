@font-face {
  font-family: LatamSans;
  font-weight: normal;
  src: url("fonts/LatamSans-Regular.ttf") format("opentype");
}

@font-face {
  font-family: LatamSans;
  font-weight: bold;
  src: url("fonts/LatamSans-Bold.ttf") format("opentype");
}

@font-face {
  font-family: LatamSans;
  font-weight: 200;
  src: url("fonts/LatamSans-Light.ttf") format("opentype");
}

@font-face {
  font-family: LatamSans;
  font-weight: 800;
  src: url("fonts/LatamSans-Black.ttf") format("opentype");
}

.App {
  text-align: center;
  font-family: LatamSans, Helvetica, sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body {
  margin: 0 auto;
  padding:0 0;
}

.App {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-content: center;
  background:grey;
}

.App > div {
  display: contents;
}

#desktop {
  width:175vh;
  height: 100vh;
  max-width:1900px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  .clickme {
    cursor: pointer;
  }
}

#desktop .ar-desktop {
  height:0;
  width:100%;
  padding-top: 56.7%;
  background-color: rgba(238, 238, 239, 0.49);
  position:relative;
}

#desktop .ar-desktop > div {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  box-sizing: border-box;
  overflow:hidden;
}

$menusize: 54%;
$menu-color: #200a78;

#desktop #navigation {
  position: absolute;
  top:0;
  left:0;
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 50;
  pointer-events: none;

  button {
    border:0 none;
    padding:0;
    width: 26px;
    height: 28px;
    background:none;
    cursor: pointer;
    transition: all 33ms ease-in-out;

    &:hover {
      transform: scale(1.1);
      transition: all 100ms ease-in-out;
    }

    img {
      width:100%;
      height: auto;
    }
  }

  header {
    position: absolute;
    top:0;
    left:0;
    width: 33%;
    display: block;
  }

  .left-bar {
    background: $menu-color;
    padding-top: 13%;
    width:3.33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: all;

    //&:after {
    //  position: absolute;
    //  content: '';
    //  background: cyan;
    //  top:55%;
    //  left: 0;
    //  height: 4.4%;
    //  width:3.33%;
    //}

    button {
      margin-bottom: 15px;
    }
  }

  .arrows {
    position: absolute;
    bottom: 0%;
    right:0%;
    pointer-events: all;

    button {
      margin-right: 7px;
      margin-left: 7px;
      width:22px;
    }
  }
}

#desktop #navigation-home {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 50;
  pointer-events: none;

  button {
    border: 0 none;
    padding: 0;
    width: 28px;
    height: 28px;
    background: none;
    cursor: pointer;
    transition: all 33ms ease-in-out;

    &:hover {
      transform: scale(1.1);
      transition: all 100ms ease-in-out;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .arrows.home {
    position: absolute;
    right: 2em;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    pointer-events: all;
  }

  .left-bar {
    pointer-events: all;
    width: 41%;
    display: flex;
    align-items: flex-end;
    height: 50%;
    position: absolute;
    left: 6em;
    bottom: 8em;
    color: red;

    .location-menu {
      margin-left:1em;
      font-weight: bold;
      span {cursor: pointer;}
      //display: none;
      ul.languages { display: block;
       li {
         cursor: pointer;
       }
      }
    }
  }
}



#desktop .menu-wrapper {
  position: absolute;
  top:0;
  left:-($menusize);
  background-color: $menu-color;
  background-image: url("img/menu-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
  overflow: hidden;
  overflow-y: auto;
  padding: 2em;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  /*width: 38%;*/
  width: $menusize;
  justify-content: space-between;
  box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.3);
  z-index: 99;
  transition: all 330ms ease-in-out;

  &.on {
    left:0;
    transition: all 330ms ease-in-out;
  }

  .col1, .col2, .col {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 1em;
  }

  .col2 {
    padding-bottom: 12px;
  }

  .location-menu {
    justify-content: flex-start;
    padding-top: 3em;
    margin-left: 0;
    padding-right: 1em;
    ul.languages {display: block;}
  }

  .menu-close-btn {
    position: absolute;
    top:1em;
    right: 1em;
    width:64px;
    height:36px;
  }



  button {
    border:0 none;
    padding:0;
    width: 32px;
    height: 32px;
    background:none;
    cursor: pointer;
    transition: all 33ms ease-in-out;

    &:hover {
      transform: scale(1.1);
      transition: all 100ms ease-in-out;
    }

    img {
      width:100%;
      height: auto;
    }
  }
}


#desktop {
  //font-size: 11px;
  font-size: 13px;
}

@media screen and (max-height: 740px) {
  #desktop {
    font-size: 10px;
    // font-size: 13px;
  }

  #desktop .menu-wrapper {
    padding: 1em 1.6em;

    .location-menu {
      padding-top: 6em;
    }

    .menu-close-btn {
      top:2em;
    }
  }
}

#desktop ul {
  text-align: left;
  margin-top:0;
  display:block;
  padding: 0;
  padding-left: 0;
  margin-bottom: 0.5em;

  ul {

  }

  li {
    color: #FFFFFF;
    text-transform: none;
    list-style: none;
    line-height: 1.5;
    cursor:pointer;

     &:not(.red) span {
       display: inline-block;
       transition: all 100ms ease-in-out;

       &:hover {
         margin-left: 5px;
         color:cyan;
         text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3);
         transition: all 100ms ease-in-out;
       }
     }
  }

  li.upper {
    text-transform: uppercase;
    font-weight: 800;
  }

  li.red {
    text-transform: uppercase;
    font-weight: bolder;
    color:rgba(255,0,0,1);
    position:relative;

    & > ul:before {
      content:'';
      position: absolute;
      width: 8px;
      height: 99%;
      top:0;
      left:-12px;
      border-left: solid 1px red;
      border-top: solid 1px red;
      border-bottom: solid 1px red;
    }

    ul {
      margin-bottom: 1.2em;
    }
  }

  li.topic {
    &:before {
      content: "> ";
    }

    font-weight: normal;
  }
}

#desktop .page {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  overflow:hidden;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background:white;

  img {
    width:100%;
    height: auto;
  }

  .loading {
    background:white;
  }

  .dfs {
    position: absolute;
    width: 65%;
    height: 90%;
    top:2%;
    right:2%;
    border:solid 1px red;

    iframe {
      width:100%;
      height: 100%;
    }
  }
}


#mobile {
  border: solid 2px red;
  width: 100%;
  position: relative;
  animation-fill-mode: both;
  background: cyan;
  float:left;
  box-sizing: border-box;
  font-size: 16px;
  animation-duration: 1500ms;

  .content {
    width:100%;
    background: white;

    img {
      width: 100%;
      height: auto;
      float: left;
    }
  }

  #navigation {
    background:transparent;
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 42px;

    .arrows {
      width:100%;
      height: 100%;
      display: flex;

      > div {
        width:50%;
        box-sizing: border-box;
      }


      button {
        border: none;
        background:transparent;
        width:42px;
        height: 42px;
      }
    }
  }

  #mobile-menu {
    width:100%;
    position: fixed;


    header {
      height: 90px;
      background:rgba(255,255,255,0.8);
      position: fixed;
      top:0;
      width: 100%;
      background-image: url('./img/bg-menu-mobile.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
    }

    .menu {
      background: #1F097F;
      position: fixed;
      width: 100%;
      z-index: 9999;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: auto;
      //display: none;
      opacity: 0;
      pointer-events: none;
      transition: all 220ms ease-in-out;

      &.on {
        //display: block;
        opacity: 1;
        pointer-events: all;
        transition: all 220ms ease-in-out;
      }

      .menu-header {
        width: 100%;
        height: 90px;
        background: #1F097F;
        position: fixed;
        z-index: 999;
      }

      .menu-content {
        overflow: hidden;
        overflow-y: auto;
        margin-top: 90px;
        margin-left: 1em;
        width:75%;
        //border: solid 4px cyan;
        box-sizing: border-box;
      }

      .languages {
        display: block;
      }

      .location-menu {
        width: 10%;
        position: absolute;
        top: 90px;
        right: 5px;
      }
    }

    button {
      border: 0;
      background: transparent;
    }

    .logo, .menu-but {
      width:40%;
      float: right;
      padding: 16px;
      box-sizing: border-box;
      img {
        max-width: 100%;
        max-height: 60px;
      }
    }
    .menu-but {
      width: 60%;
      height: 100%;
      text-align: left;
      float: left;
      padding: 16px;


      img {
        height: auto;
        width: 32px;
      }
    }


    ul {
      text-align: left;
      margin-top:0;
      display:block;
      padding: 0;
      padding-left: 0;
      margin-bottom: 0.5em;

      ul {

      }

      li {
        color: #FFFFFF;
        text-transform: none;
        list-style: none;
        line-height: 1;
        cursor:pointer;
        margin-bottom: 1em;


        span {
          display: inline-block;
          padding-left: 0.5em;
          width: 96%;
          box-sizing: border-box;
        }

        &:not(.red) span {
          display: inline-block;
          transition: all 100ms ease-in-out;

          //& {
          //  margin-left: 5px;
          //  color:cyan;
          //  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3);
          //  transition: all 100ms ease-in-out;
          //}
        }
      }

      li.upper {
        text-transform: uppercase;
        font-weight: 800;

        & > span {
          background-color: rgba(255, 255, 255, 0.1);
        }

        & > ul {
          // display: none;
        }

        &.active .topic, &.active ul {
          display: inherit;
        }
      }

      li.red {
        text-transform: uppercase;
        font-weight: bolder;
        color:rgba(255,0,0,1);
        position:relative;
        margin-top: 0.6em;

        & > ul:before {
          content:'';
          position: absolute;
          width: 8px;
          height: 99%;
          top:0;
          left:-12px;
          border-left: solid 1px red;
          border-top: solid 1px red;
          border-bottom: solid 1px red;
        }

        ul {
          margin-top: 0.6em;
          margin-bottom: 1.2em;
          max-width: 100%;
        }


      }

      li.topic:first-child {
        margin-top:1em;
      }
      li.topic {

        position: relative;
        padding-left: 0.7em;
        max-width: 100%;

        &:before {
          content: "> ";
          position: absolute;
          left: 0.5em;
        }

        font-weight: normal;
      }
    }


  }

}
